import {useQuery} from "@tanstack/vue-query";
import {client} from "@/api/client";
import {Ref, unref} from "vue";
import {FLMCohort, UtilityId} from "@/stores/flm/types";
import {calculateMonthlyEventPerformance} from "@/stores/flm/util";
import {DateTime} from "luxon";
import {useFLMStore} from "@/stores/flm";
import {EventPerformance} from "@/api/client/site";
import {MaybeRef} from "@/api/queryClient";

export function getEventPerformanceQueryKey(id: MaybeRef<UtilityId | number | null>) {
  const store = useFLMStore();

  return {
    queryKey: ['eventPerformance', id],
    queryFn: async () => {
      const unwrappedId = unref(id);
      let data: {events: EventPerformance[]}, commissionMonth: DateTime;

      if (typeof unwrappedId === 'number') {
        data = await client.site.getPerformances(unwrappedId);
        commissionMonth = DateTime.fromISO(store.sites[unwrappedId]?.commissionMonth);
      } else {
        data = await client.utility.getPerformances(unwrappedId as UtilityId);
        commissionMonth = DateTime.fromISO(store.utilities[unwrappedId as UtilityId]?.commissionMonth);
      }

      const events = data.events.sort((a, b) => a.startDt < b.startDt ? 1 : -1);
      return {
        raw: events,
        monthly: calculateMonthlyEventPerformance(events, commissionMonth),
      };
    },
  };
}

export function useEventPerformanceQuery(id: Ref<UtilityId | number | null>) {
  return useQuery({
    ...getEventPerformanceQueryKey(id),
    enabled: () => !!id,
  });
}

export function useEventLoadsQuery(id: Ref<UtilityId | number | null>, eventId: Ref<number | undefined>, cohorts?: Ref<Array<FLMCohort>>) {
  return useQuery({
    queryKey: ['eventLoads', id, eventId, cohorts],
    queryFn: async () => {
      if (!eventId.value || !id.value)
        return;

      const data = typeof id.value === 'number'
          ? await client.site.getEventLoads(id.value, eventId.value)
          : await client.utility.getEventLoads(id.value, eventId.value, {params: {cohorts: cohorts?.value?.join(",")}});
      return data.rows;
    },
    enabled: () => !!eventId.value && !!id.value,
  });
}
