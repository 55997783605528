<template>
  <v-overlay :absolute="absolute"
             :opacity="!absolute ? 1 : 0.5"
             :value="loading !== false"
             :z-index="!absolute ? 4 : 3">
    <v-progress-circular v-if="loading === true" :size="!absolute ? 64 : 32" indeterminate />
    <v-icon v-else-if="icon && loading == null" color="red" x-large>mdi-alert-circle-outline</v-icon>
    <span v-else-if="failedMessage && loading === null" class="text-center">
      {{ failedMessage }}
    </span>
  </v-overlay>
</template>

<script lang="ts" setup>
import {useAppStore} from "@/stores/app";
import {watch} from "vue";

const props = withDefaults(defineProps<{
  loading: boolean | null;
  absolute?: boolean;
  failedMessage?: string;
  icon?: boolean | null,
  showToast?: boolean,
}>(), {
  loading: false,
  absolute: false,
  failedMessage: "An error occurred while loading. Reload to try again.",
  icon: null,
  showToast: false,
});

const appStore = useAppStore();

watch(() => props.loading, () => {
  if (props.loading === null) {
    appStore.showDataRetrievalError();
  }
});

</script>

<style scoped>

</style>
