<template>
  <div class="d-flex flex-grow-1">
    <!-- Navigation -->
    <v-navigation-drawer v-model="isDrawerExpanded"
                         :dark="menuTheme === 'dark'"
                         :light="menuTheme === 'light'"
                         :right="$vuetify.rtl"
                         app
                         class="elevation-1"
                         floating>
      <!-- Navigation menu info -->
      <template #prepend>
        <div class="pa-2">
          <div class="d-flex">
            <span class="title font-weight-bold text-uppercase primary--text text-no-wrap">{{ product.name }}</span>
            <img alt="logo" src="/logo/full.svg" class="ml-auto" style="height: 30px;">
          </div>
          <div class="overline grey--text">
            {{ product.subtitle || '&nbsp;' }}
          </div>
        </div>
      </template>

      <!-- Navigation menu -->
      <main-menu :menu="navigation.menu" />

      <!-- Navigation menu footer -->
      <template #append>
        <!-- Footer navigation links -->
        <div class="pa-1 text-center">
          <v-btn v-for="(item, index) in navigation.footer"
                 :key="index"
                 :href="item.href"
                 :target="item.target"
                 small
                 text>
            {{ item.text }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar :color="isToolbarDetached ? 'surface' : undefined"
               :dark="toolbarTheme === 'dark'"
               :flat="isToolbarDetached"
               :light="toolbarTheme === 'light'"
               app>
      <v-card :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']" :flat="!isToolbarDetached"
              class="flex-grow-1 d-flex">
        <div class="d-flex flex-grow-1 align-center">
          <!-- search input mobile -->

          <div class="d-flex flex-grow-1 align-center">
            <v-btn icon text @click.stop="isDrawerExpanded = !isDrawerExpanded">
              <v-icon>mdi-menu</v-icon>
            </v-btn>

            <img v-if="utilityLogo" alt="logo" :src="utilityLogo" style="height: 32px;" class="ml-1">

            <v-spacer class="d-none d-lg-block" />

            <v-spacer class="d-block d-xs-none" />

            <!-- toolbar components -->
            <component :is="comp" v-for="comp in toolbar.components" :key="comp.name" class="mx-0" />
            <toolbar-user class="mx-0" />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container :class="{'py-1 px-3': globalError !== null}" :fluid="!isContentBoxed" class="fill-height">
        <loading-overlay :loading="isLoading" />
        <v-layout :class="{'d-none': globalError !== null}" class="px-0">
          <slot />
        </v-layout>

        <v-layout v-if="globalError !== null" class="d-block">
          <v-btn icon small text @click="back">
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
          <v-btn v-if="globalError.isDismissible" class="float-right" icon small text @click="appStore.clearError()">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <div class="text-center mx-auto mb-10 mt-2" style="max-width: 450px; top: 30%; position:relative;">
            <img alt="logo" class="mb-2" height="64" src="/logo/full.svg">
            <h1>{{ globalError.title }}</h1>
            <h4>
              {{ globalError.message }}
            </h4>
          </div>
        </v-layout>
      </v-container>
      <v-footer app inset>
        <v-spacer />
        <div class="overline">
          © Dynamic Organics {{ year }} - Proprietary and Confidential - Pilot v{{ version }}
        </div>
      </v-footer>
    </v-main>
  </div>
</template>

<script lang="ts" setup>
import {storeToRefs} from "pinia";
import MainMenu from "@/components/navigation/MainMenu.vue";
import {DateTime} from "luxon";
import {useAppStore} from "@/stores/app";
import ToolbarUser from "@/components/toolbar/ToolbarUser.vue";
import {computed} from "vue";
import LoadingOverlay from "@/components/util/LoadingOverlay.vue";
import router from "@/router";
import {useUtilityLogo} from "@/composables/useUtilityLogo";

const appStore = useAppStore();
const {
  isLoading,
  isDrawerExpanded,
  product,
  isContentBoxed,
  menuTheme,
  toolbarTheme,
  isToolbarDetached,
  version,
  navigation,
  toolbar,
  globalError,
} = storeToRefs(appStore);

const year = computed(() => {
  return DateTime.now().year;
});

const utilityLogo = useUtilityLogo();

function back() {
  router.back();
}
</script>

<style scoped>
.buy-button {
  box-shadow: 1px 1px 18px #ee44aa;
}
</style>

