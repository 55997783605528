<template>
  <v-menu left offset-y transition="slide-y-transition">
    <template #activator="{ on }">
      <v-btn class="elevation-2" icon v-on="on">
        <v-avatar size="40">
          <avatar :username="name" />
        </v-avatar>
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list dense nav>
      <v-list-item v-for="(item, index) in toolbar.user"
                   :key="index"
                   :disabled="item.disabled"
                   :exact="item.exact"
                   :href="item.href"
                   :to="item.to"
                   link>
        <v-list-item-icon>
          <v-icon :class="{ 'grey--text': item.disabled }" small>
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1" />

      <v-list-item @click="toggleTheme()">
        <v-list-item-icon>
          <v-icon small>
            {{ globalTheme === 'dark' ? 'mdi-white-balance-sunny' : 'mdi-weather-night' }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ globalTheme === 'dark' ? 'Light Theme' : 'Dark Theme' }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
// @ts-ignore
import Avatar from 'vue-avatar';
import {storeToRefs} from "pinia";
import {useAppStore} from "@/stores/app";
import {computed} from "vue";
import keycloak from "@/plugins/keycloak";

const appStore = useAppStore();
const {globalTheme, toolbar} = storeToRefs(appStore);

const name = computed(() => {
  return keycloak.tokenParsed!.name;
});

function toggleTheme() {
  const theme = appStore.globalTheme === 'dark' ? 'light' : 'dark';
  appStore.setGlobalTheme(theme);
}
</script>
