<template>
  <v-app>
    <!-- Layout component -->
    <component :is="currentLayout" v-if="isRouterLoaded">
      <transition mode="out-in" name="fade">
        <router-view />
      </transition>
    </component>

    <v-snackbar v-model="toast.show" :color="toast.color" :timeout="toast.timeout" bottom>
      {{ toast.message }}
      <v-btn v-if="toast.timeout === 0" color="white" text @click="toast.show = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts" setup>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import {computed} from "vue";
import {useRoute} from "vue-router/composables";
import {storeToRefs} from "pinia";
import {useAppStore} from "@/stores/app";
import ErrorLayout from "@/layouts/ErrorLayout.vue";
import SimpleLayout from "@/layouts/SimpleLayout.vue";

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
const appStore = useAppStore();
const {toast} = storeToRefs(appStore);

const route = useRoute();

const layouts = {
  DefaultLayout,
  ErrorLayout,
  SimpleLayout,
};

const isRouterLoaded = computed(() => {
  return route.name !== null;
});
const currentLayout = computed(() => {
  return layouts[route.meta?.layout ?? "DefaultLayout"];
});


</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

<style>
html {
  /* without this the y scroll is always visible */
  overflow-y: auto;
}
</style>
