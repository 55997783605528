import _ from "lodash";
import {useFLMStore} from "@/stores/flm";
import {useAppStore} from "@/stores/app";
import keycloak from "@/plugins/keycloak";
import type {NavigationGuardNext, Route} from "vue-router";
import {useAuthStore} from "@/stores/auth";

// set page title to "Page Title - Site Title"
// defaults to "Site Title" if no page title set in meta
export const titleGuard = (to, from, next) => {
  const appStore = useAppStore();
  if (!("getExternal" in to.meta)) {
    const pageTitle = to.meta.title ?? _.startCase(to.name?.replace(/-/g, " "));
    const siteTitle = appStore.product.name;
    document.title = pageTitle ? `${pageTitle} - ${siteTitle}` : siteTitle;
  }
  next();
};

export const subtitleGuard = (to, from, next) => {
  const store = useAppStore();
  store.product.subtitle = to.meta?.subtitle ?? null;
  next();
};

// if `getExternal` callback is provided in meta, will window.open the link/target
export const externalLinkGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
  if (to.meta?.getExternal) {
    const obj = to.meta.getExternal();
    window.open(obj.href, obj.target ?? "_self");
    return;
  }
  next();
};

export const globalErrorGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
  const store = useAppStore();
  if (store.globalError)
    store.clearError();

  return next();
};

// if public, pass
// if not public, require login and allow if hasAuth isn't defined or returns true
export const authGuard = async (to: Route, from: Route, next: NavigationGuardNext) => {
  if (to.meta?.isPublic)
    return next();

  if (!keycloak.authenticated)
    return window.location.replace(await keycloak.createLoginUrl());

  const authStore = useAuthStore();
  const flmStore = useFLMStore();
  // redirect to no sites page if not already there and route isn't public
  if (Object.keys(flmStore.sites).length === 0)
    return next({"name": "403-no-sites"});

  if (!to.meta?.hasAuth)
    return next();

  if (to.meta.hasAuth(authStore, flmStore))
    return next();

  return next({name: '403'});
};
