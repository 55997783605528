import {FetchQueryOptions, QueryClient} from "@tanstack/vue-query";
import {unref} from "vue";
import {AxiosError} from "axios";
import {ComputedRef, Ref} from "vue-demi";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: Error) => {
        if (failureCount > 0)
          return false;

        if (error instanceof AxiosError && error.response)
          if (error.response.status >= 400 && error.response.status < 500)
            return false;

        return true;
      },
      staleTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

type AsyncFunction = ((...args: any[]) => Promise<any>);
export type MaybeRef<T> = Ref<T> | ComputedRef<T> | T;
export type MaybeRefAll<T> = {
  [K in keyof T]: MaybeRef<T[K]>;
};

export function getQuery<T extends AsyncFunction>(
    fn: T,
    ...args: MaybeRefAll<Parameters<T>>
): FetchQueryOptions<Awaited<ReturnType<T>>> {
  return {
    queryKey: [fn.name, ...args],
    queryFn: async (): Promise<Awaited<ReturnType<T>>> => {
      const unwrappedArgs = args.map((arg: MaybeRef<unknown>) => unref(arg));
      return await fn(...unwrappedArgs) as Awaited<ReturnType<T>>;
    },
  };
}
